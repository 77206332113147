import { Component } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ConnectServerService } from '../../services/connect-server.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    CommonModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  toggled: boolean = true;
  pswVisibility = "password";

  loginForm = new FormGroup({
    email: new FormControl<null | string>(null, [Validators.required, Validators.email]),
    password: new FormControl<null | string>(null, Validators.required)
  })

  hasError = false;


  constructor(private authUser: AuthService, public router: Router,
    private connectServerService: ConnectServerService) {

  }

  async checkAccess() {
    try {
      await this.authUser.loginUser(this.loginForm.get('email')?.value!, this.loginForm.get('password')?.value!);
      // console.log('Token: ', this.authUser.getToken());
      if (this.authUser.getToken() != null) {
        this.hasError = false;
        // this.router.navigate(['/home']);
        window.location.href = '/home';
      }
    } catch (error) {
      this.hasError = true;
      console.error('Login failed', error);
    }
  }

  // logOut(){
  //   this.authUser.logoutUser();
  // }

  seePassword() {
    if(this.toggled) {
      this.pswVisibility = "text";
    }
    else {
      this.pswVisibility = "password";
    }
    this.toggled = !this.toggled;
  }

  checkEmail() {
    const email_val = this.loginForm.get('email')?.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(email_val!)) {
      this.loginForm.setErrors(null);
    }
    else {
      this.loginForm.setErrors({invalid: true});
    }
  }
}
