import { CommonModule } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageViewerComponent } from "../image-viewer/image-viewer.component";
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer';
import { ArticleFile } from '../../../../interfaces/image';
import { Connect } from '../../../../classes/connect';

@Component({
  selector: 'app-modal-viewer',
  standalone: true,
  imports: [
    CommonModule,
    ImageViewerComponent,
    PdfViewerModule
],
  templateUrl: './modal-viewer.component.html',
  styleUrl: './modal-viewer.component.scss'
})
export class ModalViewerComponent {

  @ViewChild('pdfViewer', { static: false }) pdfViewer!: PdfViewerComponent;

  zoom: number = 0.8;

  fileId: number;
  extension: string;
  currentSrc: string | null = null;
  urlServerLara = Connect.urlServerLara;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {article: ArticleFile}, private dialogRef: MatDialogRef<ModalViewerComponent>,) {
    //this.isImage = this.checkIfImage(data);
    this.currentSrc = this.urlServerLara + data.article.src;
    this.extension = data.article.ext;
    this.fileId = data.article.id;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  zoomIn() {
    if (this.zoom < 1.8) { 
      this.zoom = 2; 
      this.updateZoom();
    }
  }

  zoomOut() {
    if (this.zoom > 0.8) { 
      this.zoom = 0.8; 
      this.updateZoom();
    }
  }

  updateZoom() {
    if (this.pdfViewer) {
      this.pdfViewer.zoom = this.zoom; 
    }
  }

  // checkIfImage(fileName: string): boolean {
  //   const ext = fileName.split('.').pop()?.toLowerCase();
  //   return ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif';
  // }
}
