<div class="container-fluid fullscreen-modal">
    <div class="row justify-content-center align-items-center mt-2 borderBottom">
        <div class="col-2 col-md-4 text-center mb-2 cursor pageSelector d-none d-sm-block">
            <button class="btn btn-outline-dark" id="prevBtn" (click)="previousPage()">
                <i class="bi bi-arrow-left me-0 me-md-2"></i>
                <span class="d-none d-md-inline">Previous Page</span>
            </button>
        </div>
        <div class="col-6 col-sm-4 col-md-4 text-center mb-2 cursor">
            <div class="btn btn-outline-dark me-2" (click)="zoomIn()">
                <i class="bi bi-zoom-in"></i>
            </div>
            <span class="d-none d-md-inline">Zoom</span>
            <div class="btn btn-outline-dark ms-2" (click)="zoomOut()">
                <i class="bi bi-zoom-out"></i>
            </div>
        </div>
        <div class="col-2 col-md-4 text-center mb-2 d-none d-sm-block">
            <button class="btn btn-outline-dark" id="nextBtn" (click)="nextPage()">
                <span class="d-none d-md-inline">Next Page</span>
                <i class="bi bi-arrow-right ms-0 ms-md-2"></i>
            </button>
        </div>
        <div class="col-2 text-center d-block d-md-none mb-2">
            <button class="btn btn-outline-dark" (click)="closeDialog()">
                <i class="bi bi-x ms-0 ms-xl-2"></i>
            </button>
        </div>
    </div>

    <div class="closeButton d-none d-md-block">
        <button class="btn btn-outline-dark" (click)="closeDialog()">
            <i class="bi bi-x"></i>
        </button>
    </div>
</div>

<div class="container-fluid" style="overflow: scroll;">
    <div #scrollContainer class="row justify-content-center mt-1 p-0 p-sm-1 p-md-2">
        <img class="scroll-container text-center col-12" #image *ngIf="currentSrc != null"
            [src]="urlServerLara + currentSrc" [style.transform]="'scale(' + zoomLevel + ')'" (load)="adjustRowHeight()">
    </div>
</div>