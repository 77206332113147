<div class="container-fluid" style="min-height: 80%; min-width: 350px; background-color: #F2F2F2;">

    <div class="row justify-content-center align-items-center pt-3">
        <div class="col-12 col-md-1 text-start mb-1">
            <button class="btn btn-sm btn-outline-dark" (click)="goBack()">
                <i class="bi bi-arrow-left"></i>
            </button>
        </div>

        <div class="col-12 col-md-10 text-center mb-1">
            <span style="font-size: xx-large; font-weight: bold;">{{article?.title}}</span>
        </div>

        <div class="d-none d-md-block col-1">

        </div>
    </div>

    <div class="row ps-md-5 pe-md-5 p-0 p-sm-1 p-md-3 p-lg-3 p-xl-4 justify-content-center pagePadding rowWidth">
        <div class="col-12 order-1 order-md-2 col-lg-5">
            <div class="col-12 pb-lg-2 pt-2 pt-lg-0">
                <div class="card" style="height: 100%;">
                    <div class="card-header text-center" style="background-color: #1E73BE;">
                        <span style="font-weight: bold; color: white; font-size: large;">Dati Scheda</span>
                    </div>
                    <div class="card-body">
                        <div class="innerTextFormat row align-items-center">
                            <div class="col-12 mb-1" style="font-style: italic; color: gray;">
                                Codice Articolo: {{article?.code}}
                            </div>
                            <div class="col-12 mb-1">
                                <strong> Titolo: </strong> {{article?.title}}
                            </div>
                            <div class="col-12 mb-1">
                                <strong> Categoria: </strong> {{article?.category?.title}}
                            </div>
                            <div *ngIf="article?.subcategory != null" class="col-12 mb-1">
                                <strong> Sottocategoria: </strong> {{article?.subcategory?.title}}
                            </div>
                            <div *ngIf="article?.date_type === 0" class="col-12 mb-1">
                                <strong> Data: </strong> {{article?.date_article | date: 'dd/MM/yyyy'}}
                            </div>
                            <div *ngIf="article?.date_type === 1" class="col-12 mb-1">
                                <strong> Anno: </strong> {{article?.year_article}}
                            </div>
                            <div *ngIf="article?.date_type === 2" class="col-12 mb-1">
                                <strong> Periodo: </strong> da {{article?.yearfrom_article}} a
                                {{article?.yearto_article}}
                            </div>
                            <div *ngIf="article?.city !== null" class="col-12 mb-1">
                                <strong> Città: </strong> {{article?.city}}
                            </div>
                            <div *ngIf="article?.pointofinterest !== null" class="col-12 mb-1">
                                <strong> Luogo di interesse: </strong> {{article?.pointofinterest}}
                            </div>
                            <div *ngIf="article?.storage_place !== null && authService.isLoggedIn" class="col-12 mb-1">
                                <strong> Luogo di conservazione: </strong> {{article?.storage_place}}
                            </div>
                            <div class="col-6 mb-1">
                                <strong> Piano: </strong> {{article?.floor}}
                            </div>
                            <div class="col-6 mb-1">
                                <strong> Stanza: </strong> {{article?.room}}
                            </div>
                            <div class="col-6 mb-1">
                                <strong> Scaffale: </strong> {{article?.rack}}
                            </div>
                            <div class="col-6 mb-1">
                                <strong> Contenitore: </strong> {{article?.case}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="article?.description" class="d-none d-lg-block col-12 pb-lg-2 pt-2 pt-lg-0">
                <div class="card" style="height: 100%;">
                    <div style="background-color: #1E73BE;" class="card-header text-center">
                        <span style="font-weight: bold; color: white; font-size: large;">Descrizione</span>
                    </div>
                    <div class="card-body">
                        <div class="innerTextFormat">
                            <p class="text-center" style="font-size: medium; font-style: italic;">
                                {{article?.description}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-7 ms-4 me-4 ms-lg-0 me-lg-0 pt-2 pt-lg-0 order-2 order-lg-1 text-center">
            <div id="carouselBody">
                <div id="custCarousel" class="carousel slide" data-ride="carousel">
                    <!-- slides -->
                    <div *ngFor="let img of files; let i = index" class="carousel-inner">
                        <div class="carousel-item" [class.active]="i === currentImg">
                            <img *ngIf="img.ext != 'pdf'" class="coverImg mt-4" [src]="urlServerLara + img.src"
                                matTooltip="Visualizza immagine a schermo intero" [title]="img.title"
                                (click)="openFullscreenModal(img)">
                            <img *ngIf="img.ext == 'pdf'" class="coverImg mt-4" src="assets/img/pdfCover.png"
                                matTooltip="Visualizza immagine a schermo intero" [title]="img.title"
                                (click)="openFullscreenModal(img)">
                        </div>
                    </div>

                    <a class="carousel-control-prev arrow" data-bs-target="#custCarousel" (click)="prevImg()">
                        <span class="carousel-control-prev-icon" style="filter: invert(1);"></span>
                    </a>
                    <a class="carousel-control-next arrow" data-bs-target="#custCarousel" (click)="nextImg()">
                        <span class="carousel-control-next-icon" style="filter: invert(1);"></span>
                    </a>

                    <!-- Container per le thumbnails con overflow -->
                    <div class="thumbnail-slider-wrapper">
                        <!-- Thumbnails -->
                        <ol class="carousel-indicators list-inline thumbList" id="thumbListScroll">
                            <li class="list-inline-item" *ngFor="let img of this.files; let i = index;"
                                [class.active]="i === currentImg">
                                <a id="carousel-selector-{{i}}" class="selected" data-target="#custCarousel">
                                    <img *ngIf="img.ext != 'pdf'" class="img-fluid modalImg"
                                        [src]="urlServerLara + img.src" [title]="img.title" (click)="goToImg(i)">
                                    <img *ngIf="img.ext == 'pdf'" src="assets/img/pdfThumbnail.png"
                                        class="img-fluid modalImgPdf" [title]="img.title" (click)="goToImg(i)">
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>


        <!-- <div class="containerArrows">
            <div class="arrow-left d-none d-md-block" (click)="goPrev()" *ngIf="hasPreviousImage()">
                <img src="assets/img/leftArrow.png" alt="Previous" style="height: 50px;">
            </div>
            <div class="arrow-right d-none d-md-block" (click)="goNext()" *ngIf="hasNextImage()">
                <img src="assets/img/rightArrow.png" alt="Next" style="height: 50px;">
            </div>
        </div> -->

        <!-- <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-body d-flex justify-content-center">
                    <img src="{{modalImageUrl}}" class="modalImg">
                </div>
            </div>
        </div> -->

        <!-- <div class="col-12 carouselBg">

            <div class="blurred-overlay" [style.background-image]="'url(' + getBlurredImage() + ')'"></div>

            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div id="carousel" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner text-center">
                            <div *ngFor="let img of article.src; let i = index" class="carousel-item" [class.active]="i === 0">
                                <img id="coverImage" [src]="img" class="coverImg" (click)="navigate()">
                            </div>
                        </div>
                        <a class="carousel-control-prev" role="button" data-bs-target="#carousel" data-bs-slide="prev">
                            <i class='bi bi-arrow-left sliderArrowPrev'></i>
                        </a>
                        <a class="carousel-control-next" role="button" data-bs-target="#carousel" data-bs-slide="next">
                            <i class='bi bi-arrow-right sliderArrowNext'></i>
                        </a>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <ng-container *ngFor="let imageUrl of article.src; let i = index">
                        <div class="col-md-2 mb-4 mt-1" style="width: 100px; height: 100px;">
                            <img [src]="imageUrl" class="img-fluid thumbnailImg">
                        </div>
                    </ng-container>
                </div>
            </div>
        </div> -->



        <!-- <div class="col-12 col-lg-6 text-center mt-3">
            <div class="row justify-content-center bg-carousel">
                <div class="col-12 mt-1 carousel slide" id="carousel" style="width: 100%;">
                    <div class="carousel-inner">
                        <div class="carousel-item" *ngFor="let imageUrl of article.src; let i = index"
                            [class.active]="i === 0">
                            <img *ngIf="showModal" [src]="imageUrl" class="coverImg" data-bs-toggle="modal"
                                data-bs-target="#myModal" (click)="setModalContent(imageUrl)">
                            <img *ngIf="!showModal" [src]="imageUrl" class="coverImg">
                        </div>
                    </div>
                    <a class="carousel-control-prev" role="button" data-bs-target="#carousel" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </a>
                    <a class="carousel-control-next" role="button" data-bs-target="#carousel" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </a>
                </div>
            </div>
            <div class="row justify-content-center mt-2">
                <ng-container *ngFor="let imageUrl of article.src; let i = index">
                    <div class="col-md-2 mb-4 mt-1" style="width: 100px; height: 100px;">
                        <img [src]="imageUrl" class="img-fluid thumbnailImg">
                    </div>
                </ng-container>
            </div>
        </div> -->
    </div>

    <!-- MODIFICA SCHEDA ED ELIMINA -->
    <div *ngIf="authService.isLoggedIn(); else notLoggedBlock" class="row text-center justify-content-center mt-4 pb-4">
        <div class="col-12 col-sm-6 col-md-4 col-xl-2">
            <button class="btn btn-warning mt-2" [routerLink]="['/modificaSchedaStorica', article?.id]"
                style="width: 170px;">
                <i class="bi bi-pencil"></i>
                Modifica Scheda
            </button>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-xl-2">
            <button class="btn btn-danger mt-2" (click)="deleteArticle(article?.id!)" style="width: 170px;">
                <i class="bi bi-trash"></i>
                Elimina
            </button>
        </div>
    </div>

    <ng-template #notLoggedBlock>
        <div *ngIf="false" class="row text-center justify-content-center mt-4 pb-4">
            <div class="col-12 col-md-6 col-xl-4">
                <button class="btn btn-sm btn-outline-success mt-2" [routerLink]="['/richiediAccesso']">
                    <i class="bi bi-person"></i>
                    Richiedi Visualizzazione Completa
                </button>
            </div>
        </div>
    </ng-template>
</div>
