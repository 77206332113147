import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ArticlesService } from '../../../services/articles.service';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { CommonModule, IMAGE_CONFIG } from '@angular/common';
import { AuthService } from '../../../services/auth.service';
import { Article } from '../../../interfaces/article';
import { ArchiveFilterService } from '../../../services/archive-filter.service';
import { ArticleFile } from '../../../interfaces/image';
import { Connect } from '../../../classes/connect';
import { ApiResponse } from '../../../interfaces/api-response';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';
import { ModalViewerComponent } from '../components/modal-viewer/modal-viewer.component'
import { PopupDialogService } from '../../../services/popup-dialog.service';
import { ConnectServerService } from '../../../services/connect-server.service';
import { ImageViewerComponent } from '../components/image-viewer/image-viewer.component';

@Component({
  selector: 'app-article-historical',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatTooltip,
    ModalViewerComponent,
  ],
  templateUrl: './article-historical.component.html',
  styleUrl: './article-historical.component.scss',
})
export class ArticleHistoricalComponent {

  urlServerLara = Connect.urlServerLara;
  files: ArticleFile[] = [];

  showModal: boolean = false;
  modalImageUrl: string = '';
  articleData: { dimension: string, imgFormat: string, weigth: number, id: number } = { dimension: '', imgFormat: '', weigth: 0, id: 0 };
  id: number = 0;
  article!: Article | null;
  currentImg: number = 0;

  constructor(private route: ActivatedRoute, public articlesService: ArticlesService, private router: Router,
    private viewportRuler: ViewportRuler, public authService: AuthService, private archiveFilterService: ArchiveFilterService,
    public dialog: MatDialog, public popupDialogService: PopupDialogService, private connectServerService: ConnectServerService) { }

  ngOnInit(): void {
    this.checkWindowSize();
    this.initialize();
  }

  openFullscreenModal(articleFile: ArticleFile): void {

    const id = articleFile.id;
    if (id > 0) {
      if (articleFile.ext == "pdf") {
        this.dialog.open(ModalViewerComponent, {
          data: { article: articleFile },
          panelClass: 'fullscreen-modal',
          width: '80vw',
          height: 'auto',
          maxHeight: '86vh',
          minWidth: '250px',
        });
      }
      else {
        this.dialog.open(ImageViewerComponent, {
          data: { article: articleFile },
          panelClass: 'fullscreen-modal',
          width: '80vw',
          height: '86vw',
          maxHeight: '86vh',
          minWidth: '250px',
        });
      }
    }
  }

  initialize() {
    this.route.params.subscribe(
      (params) => {
        //console.error('params: ', params['name'])
        this.id = params['id'];
        this.articlesService.getArticleFromServer(this.id).
          subscribe((val: ApiResponse<{ article: Article }>) => {
            this.article = val.data.article;
          });
        //this.articleData = this.articlesService.getArticleData(this.id);
        this.articlesService.getFilesServer(this.id).
          subscribe((val: ApiResponse<{ listFiles: ArticleFile[] }>) => {
            if (val.data.listFiles) {
              this.files = val.data.listFiles;
            }
          });
      }
    );

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize();
  }

  navigate(id: number) {
    this.router.navigate(['/archivio/scheda/imageviewer', id])
  }

  // getBlurredImage(): string {
  //   return this.article?.src[this.currentImg]!;
  // }

  checkWindowSize() {
    const screenWidth = this.viewportRuler.getViewportSize().width;
    if (screenWidth <= 768) {
      this.showModal = false;
    }
    else {
      this.showModal = true;
    }
  }

  setModalContent(imageUrl: string) {
    this.modalImageUrl = imageUrl;
  }

  nextImg() {
    //if(this.article?.src.length! > this.currentImg + 1) {
    if (this.files.length > this.currentImg + 1) {
      this.currentImg += 1;
    }
    //else if(this.article?.src.length === this.currentImg + 1)
    else if (this.files.length === this.currentImg + 1) {
      this.currentImg = 0;
    }
    //console.log("current img:", this.currentImg);
  }

  prevImg() {
    if (this.currentImg > 0) {
      this.currentImg -= 1;
    }
    else if (this.currentImg === 0) {
      this.currentImg = this.files.length - 1;
    }
    //console.log("current img:", this.currentImg);
  }

  goToImg(i: number) {
    this.currentImg = i;
  }

  // goNext() {
  //   this.router.navigate(['/archivio/scheda', this.articlesService.nextId]).then(() => this.initialize());
  // }

  // goPrev() {
  //   this.router.navigate(['/archivio/scheda', this.articlesService.prevId]).then(() => this.initialize());
  // }

  goBack() {
    this.router.navigate(['/archivioStorico']);
  }

  deleteArticle(id: number) {
    const obj_request: ApiResponse<any> = {
      code: 244,
      data: {},
      title: 'Info',
      message: 'Sei sicuro di voler cancellare l\'immagine?',
      obj_dialog: {
        disableClose: 0,
        obj_buttonAction:
        {
          action: 1,
          action_type: 2,
          label: 'Cancella',
          run_function: () => this.deleteArticleFromServer(id)
        }
      }
    }
    this.popupDialogService.popupDialog(obj_request);
  }

  private deleteArticleFromServer(id: number) {
    this.connectServerService.postRequest<ApiResponse<any>>(Connect.urlServerLaraApi, 'articleObsolete', { idarticle: id })
      .subscribe((val: any) => {
        this.popupDialogService.popupDialog(val);
        this.router.navigate(["archivioStorico"]);
      })
  }

  // getIdNext() {
  //   if (this.hasNextImage()) {
  //     return this.article?.id! + 1
  //   }
  //   else return null;
  // }

  // getIdPrev() {
  //   if (this.hasPreviousImage()) {
  //     return this.article?.id! - 1
  //   }
  //   else return null;
  // }

  // hasPreviousImage(): boolean {
  //   return (this.article?.id! > 1);
  // }

  // hasNextImage(): boolean {
  //   return (this.article?.id != 0 && this.articlesService.total() > this.article?.id!);
  // }

}
