import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Observable, debounceTime, filter, map, startWith, switchMap } from 'rxjs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { Filters } from '../../../../../interfaces/filters';
import { Category, City } from '../../../../../interfaces/article';
import { cityValidator } from '../../../../../validators/city-validator';
import { SearchAutocompleteService } from '../../../../../services/search-autocomplete.service';
import { ConnectServerService } from '../../../../../services/connect-server.service';
import { Connect } from '../../../../../classes/connect';
import { ApiResponse } from '../../../../../interfaces/api-response';


@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatAutocompleteModule],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent implements OnInit {

  @Output() searchFilter: EventEmitter<{ type: number, obj_filter: Filters | string | null | undefined }> =
    new EventEmitter<{ type: number, obj_filter: Filters | string | null | undefined }>();

  filteredCity$!: Observable<City[]>;
  categoryOptions: Category[] = [];
  yearFormat: string | null = null;
  isValidPeriod: boolean = true;

  filterForm = new FormGroup({
    title: new FormControl<string | null>(null),
    categories: new FormControl<Category[] | null>(null),
    city: new FormControl<City | string>({ value: '', disabled: false }, [cityValidator()]),
    pointofinterest: new FormControl<string | null>(null),
    date_type: new FormControl<number | null>(null),
    date_article: new FormControl<Date | null>(null),
    yearFrom_article: new FormControl<string | null>(null, Validators.pattern('^[0-9]{1,4}$')),
    yearTo_article: new FormControl<string | null>(null, Validators.pattern('^[0-9]{1,4}$')),
    year_article: new FormControl<string | null>(null, Validators.pattern('^[0-9]{1,4}$')),
  });

  constructor(private searchAutocompleteService: SearchAutocompleteService, private connectServerService: ConnectServerService) { }
  ngOnInit(): void {
    this.getCategories();
    this.searchCity();
  }

  searchArticles(type_val: number) {
    let val: string | null | Filters | undefined = this.filterForm.get('title')?.value;
    console.log(type_val)
    console.log(val)
    if (type_val == 1) {
      val = this.filterForm.getRawValue();
    }
    const obj = {
      type: type_val,
      obj_filter: val
    }
    this.searchFilter.emit(obj);
  }

  clearInputFilters() {
    this.filterForm.reset();
  }

  isSelected(category: Category): boolean {
    const categories = this.filterForm.get('categories')?.value;
    return categories ? categories.includes(category) : false;
  }

  private searchCity() {
    // ricerca
    const campo_city = this.filterForm.get('city');
    if (campo_city) {
      this.filteredCity$ = campo_city.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value?.name || ''),
          filter(value => value.length > 0),
          debounceTime(600),
          switchMap((value: string) =>
            value ? this.searchAutocompleteService.getCity(value) : [])
        );
    }
  }

  private getCategories() {
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'categories', {})
      .subscribe(
        (val: ApiResponse<{ categories: Category[] }>) => {
          this.categoryOptions = val.data.categories;
        }
      )
  }

  displayCityName(city?: City): string {
    return city ? city.name : '';
  }

  toggleCategory(category: Category): void {
    let categories: Category[] = this.filterForm.get('categories')?.value!;
    if (categories) {
      const index = categories.findIndex(cat => cat.id === category.id);
      if (index !== -1) {
        categories.splice(index, 1); // Rimuovi la categoria
      } else {
        categories.push(category); // Aggiungi la categoria
      }
      this.filterForm.get('categories')?.setValue(categories);
    } else {
      let firstInsertion: Category[] = new Array;
      firstInsertion.push(category);
      this.filterForm.get('categories')?.setValue(firstInsertion);
    }
  }

  changeFormat(type: string) {
    if (type === "date") {
      this.resetYearValue(type);
      if (this.yearFormat === "date") {
        this.filterForm.get('date_type')!.setValue(null);
        this.yearFormat = null;
      } else {
        this.yearFormat = type;
        this.filterForm.get('date_type')!.setValue(0);
      }
    } 
    else if (type === "year") {
      this.resetYearValue(type);
      if (this.yearFormat === "year") {
        this.filterForm.get('date_type')!.setValue(null);
        this.filterForm.get('year_article')!.setValue(null);
        this.yearFormat = null;
      } else {
        this.yearFormat = type;
        this.filterForm.get('date_type')!.setValue(1);
      }
    } 
    else if (type === "period") {
      this.resetYearValue(type);
      if (this.yearFormat === "period") {
        this.filterForm.get('date_type')!.setValue(null);
        this.yearFormat = null;
      } else {
        this.yearFormat = type;
        this.filterForm.get('date_type')!.setValue(2);
      }
    }
  }

  private resetYearValue(type: string) {
    if (type === "date") {
      this.filterForm.get('yearFrom_article')!.setValue(null);
      this.filterForm.get('yearTo_article')!.setValue(null);
      this.filterForm.get('year_article')!.setValue(null);
      this.filterForm.get('yearTo_article')!.setErrors(null);
      this.filterForm.get('yearFrom_article')!.setErrors(null);
      this.filterForm.get('year_article')!.setErrors(null);
    } else if (type === "year") {
      this.filterForm.get('yearFrom_article')!.setValue(null);
      this.filterForm.get('yearTo_article')!.setValue(null);
      this.filterForm.get('date_article')!.setValue(null);
      this.filterForm.get('yearTo_article')?.setErrors(null);
      this.filterForm.get('yearFrom_article')?.setErrors(null);
      this.filterForm.get('date_article')?.setErrors(null);
    } else if (type === "period") {
      this.filterForm.get('year_article')!.setValue(null);
      this.filterForm.get('date_article')!.setValue(null);
      this.filterForm.get('date_article')?.setErrors(null);
      this.filterForm.get('year_article')!.setErrors(null);
    }
  }

  validPeriod() {
    const yearFrom = parseInt(this.filterForm.get('yearFrom_article')?.value!);
    const yearTo = parseInt(this.filterForm.get('yearTo_article')?.value!);

    if (yearFrom || yearTo) {
      if (yearTo > yearFrom) {
        this.isValidPeriod = true;
        this.filterForm.get('yearTo_article')?.setErrors(null);
        this.filterForm.get('yearFrom_article')?.setErrors(null);
      }
      else {
        this.isValidPeriod = false;
        this.filterForm.get('yearTo_article')?.setErrors({ invalid: true });
        this.filterForm.get('yearFrom_article')?.setErrors({ invalid: true });
      }
    }
    else {
      this.isValidPeriod = false;
      this.filterForm.get('yearTo_article')?.setErrors({ invalid: true });
      this.filterForm.get('yearFrom_article')?.setErrors({ invalid: true });
    }
  }

}
