<div class="container p-1 p-md-2 p-lg-3" style="min-height: 80%; min-width: 350px; max-width: 1400px;">
    <div class="row p-1 p-sm-2 justify-content-center align-items-center text-center" style="margin-left: 0 !important; margin-right: 0 !important;">
        <div class="col-12" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 63%; left: 42%;">
                IMPOSTAZIONI
            </span>
        </div>
        <div class="col-8 text-center">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    IMPOSTAZIONI
                </span>
            </div>
        </div>
    </div>

    <div class="row p-0 justify-content-center cardRow" style="margin-left: 0 !important; margin-right: 0 !important;">
        <div class="card overflow-hidden">
            <div class="row justify-content-center">
                <div class="col-md-3 p-0 text-center columnBorder">
                    <div class="list-group list-group-flush account-settings-links options">
                        <a class="list-group-item list-group-item-action active" id="accountGeneralList"
                            data-bs-toggle="list" href="#accountGeneral" role="tab"
                            aria-controls="accountGeneral">Generali</a>
                        <!-- <a class="list-group-item list-group-item-action" data-bs-toggle="list"
                                  href="#account-notifications">{{'PROFILE.SETTINGS.NOTIFICATIONS' | translate}}</a> -->
                        <a class="list-group-item list-group-item-action" id="changePasswordList" data-bs-toggle="list"
                            href="#accountChangePassword" role="tab" aria-controls="accountChangePassword">
                            Modifica Password</a>
                    </div>
                </div>
                <div class="col-md-9 internalCard">
                    <div class="tab-content">
                        <div class="tab-pane fade active show" role="tabpanel" aria-labelledby="accountGeneralList"
                            id="accountGeneral">
                            <div class="card-body">
                                <form class="formSection" [formGroup]="generalForm">
                                    <div class="form-group">
                                        <label for="name" class="form-label section">Nome</label>
                                        <input type="text" class="form-control" value="" formControlName="name" (input)="checkGeneralForm()"
                                        [class.is-invalid]="generalForm.get('name')?.invalid && 
                                        (generalForm.get('name')?.touched || generalForm.get('name')?.dirty)">
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label section">Cognome</label>
                                        <input type="text" class="form-control" value="" formControlName="surname" (input)="checkGeneralForm()"
                                        [class.is-invalid]="generalForm.get('surname')?.invalid && 
                                        (generalForm.get('surname')?.touched || generalForm.get('surname')?.dirty)">
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label section">Email
                                        </label>
                                        <input type="text" class="form-control mb-1" value="" formControlName="email">
                                    </div>
                                </form>

                                <div class="mt-3">
                                    <div *ngIf="generalForm.invalid" class="text-center" id="email"
                                        style="color: gray; font-size: small;">
                                        <small>Compilare correttamente tutti i campi per abilitare il
                                            salvataggio</small>
                                    </div>
                                    <div class="d-flex justify-content-center mt-1">
                                        <button type="button" [disabled]="generalForm.invalid || !generalForm.dirty"
                                            class="btn btn-outline-dark" (click)="updateGeneral()">
                                            Salva
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="accountChangePassword" role="tabpanel"
                            aria-labelledby="changePasswordList">
                            <div class="card-body pb-2">
                                <form class="formSection" [formGroup]="modifyPasswordForm">
                                    <div class="form-group input-container">
                                        <label for="password1" class="form-label section">
                                            Password Attuale
                                        </label>
                                        <div class="input-group">
                                            <input [type]="type1" id="password1" formControlName="current_password"
                                                class="form-control">
                                            <button class="btn btn-outline-dark">
                                                <i class="bi bi-eye icon"
                                                    [ngClass]="{'bi-eye': !toggled1, 'bi-eye-slash': toggled1}"
                                                    id="togglePassword" (click)="seePassword('password1')">
                                                </i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-group input-container">
                                        <label for="password2" class="form-label section">
                                            Nuova Password
                                        </label>
                                        <div class="input-group">
                                            <input [type]="type2" id="password2" formControlName="password"
                                                class="form-control" (input)="checkPassword()"
                                                [class.is-invalid]="modifyPasswordForm.get('password')?.invalid &&
                                                (modifyPasswordForm.get('password')?.dirty || modifyPasswordForm.get('password')?.touched)">
                                            <button class="btn btn-outline-dark">
                                                <i class="bi bi-eye icon"
                                                    [ngClass]="{'bi-eye': !toggled2, 'bi-eye-slash': toggled2}"
                                                    id="togglePassword" (click)="seePassword('password2')">
                                                </i>
                                            </button>
                                        </div>
                                        <div *ngIf="errorMessage && !validPassword" class="mt-1" id="email"
                                            style="color: rgb(255, 0, 0); font-size: small;">
                                            <small>*La password deve essere lunga almeno 8 caratteri, contenere una
                                                maiuscola ed un simbolo speciale "[!&#64;#$%^&*(),.?":|&lt;></small>
                                        </div>
                                    </div>
                                    <div class="form-group input-container">
                                        <label for="password3" class="form-label section">
                                            Ripeti Password
                                        </label>
                                        <div class="input-group">
                                            <input [type]="type3" id="password3" formControlName="password_confirmation"
                                                class="form-control" (input)="checkPassword()"
                                                [class.is-invalid]="modifyPasswordForm.get('password_confirmation')?.invalid &&
                                                (modifyPasswordForm.get('password_confirmation')?.dirty || modifyPasswordForm.get('password_confirmation')?.touched)">
                                            <button class="btn btn-outline-dark">
                                                <i class="bi bi-eye icon"
                                                    [ngClass]="{'bi-eye': !toggled3, 'bi-eye-slash': toggled3}"
                                                    id="togglePassword" (click)="seePassword('password3')">
                                                </i>
                                            </button>
                                        </div>
                                        <div *ngIf="errorMessage && !equalPassword" class="mt-1" id="email"
                                            style="color: rgb(255, 0, 0); font-size: small;">
                                            <small>*Le due password sono differenti</small>
                                        </div>
                                    </div>
                                </form>

                                <div class="mt-3">
                                    <div *ngIf="modifyPasswordForm.invalid" class="text-center" id="email"
                                        style="color: gray; font-size: small;">
                                        <small>Compilare correttamente tutti i campi per abilitare il
                                            salvataggio</small>
                                    </div>
                                    <div class="d-flex justify-content-center mt-1">
                                        <button type="button" class="btn btn-outline-dark"
                                            [disabled]="modifyPasswordForm.invalid" (click)="saveNewPassword()">
                                            Salva
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>