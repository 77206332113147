import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {MatTableModule} from '@angular/material/table'; 
import { PopupDialogService } from '../../services/popup-dialog.service';
import { ApiResponse } from '../../interfaces/api-response';

@Component({
  selector: 'app-authorizations',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTableModule,
  ],
  templateUrl: './authorizations.component.html',
  styleUrl: './authorizations.component.scss'
})
export class AuthorizationsComponent {
  displayedColumns: string[] = ['name', 'surname', 'description', 'access'];

  usersForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private popupService: PopupDialogService) {
    Event
    this.usersForm = this.formBuilder.group({
      users: this.formBuilder.array(this.users.map(user => this.createUserGroup(user)))
    });
    this.updateDisplayedColumns(window.innerWidth);
  }

  // Listener per il cambiamento della dimensione dello schermo
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const target = event.target as Window;
    this.updateDisplayedColumns(target.innerWidth);
  }

  // Aggiorna la lista delle colonne visualizzate in base alla dimensione dello schermo
  updateDisplayedColumns(width: number): void {
    if (width < 768) { // Considera "md" come 768px (o la dimensione desiderata)
      this.displayedColumns = ['name', 'surname', 'access'];
    } else {
      this.displayedColumns = ['name', 'surname', 'description', 'access'];
    }
  }


  createUserGroup(user: any): FormGroup {
    return this.formBuilder.group({
      id_user: new FormControl(user.id_user, Validators.required),
      user_name: new FormControl(user.user_name, Validators.required),
      user_surname: new FormControl(user.user_surname, Validators.required),
      description: new FormControl(user.description, Validators.required),
      access: new FormControl(user.access, Validators.required),
    });
  }

  get usersArray(): FormArray {
    return this.usersForm.get('users') as FormArray;
  }

  onCheckboxClick(event: Event, formName: string, index: number) {
    event.preventDefault();
    let actualValue = this.usersArray.at(index).get("access")?.value;
    let message = "";
    let label = "";
    if (actualValue) {
      message = "Sei sicuro di voler rimuovere l'accesso a questo utente? Sarà inviata una mail di conferma all'utente.";
      label = "Rimuovi";
    }
    else {
      message = "Sei sicuro di volere concedere l'accesso a questo utente?";
      label = "Autorizza";
    }

    const obj_request: ApiResponse<any> = {
      code: 244,
      data: {},
      title: 'Info',
      message: message,
      obj_dialog: {
        disableClose: 0,
        obj_buttonAction:
        {
          action: 1,
          action_type: 2,
          label: label,
          run_function: () => this.modifyUserFromServer(this.usersArray.at(index).get("id_user")?.value, event, formName, index)
        }
      }
    }
    this.popupService.popupDialog(obj_request);
    
  }

  modifyUserFromServer(id: number, event: Event, formName: string, index: number) {
    //CHIAMATA AL SERVER

    //Inserire il seguente nel subscribe
    const checkbox = event.target as HTMLInputElement;
    const value = !this.usersArray.at(index).get("access")?.value;
    this.usersArray.at(index).get("access")?.setValue(value);
    console.log(this.usersArray.getRawValue());
  }

  onSubmit() {
    console.log(this.usersForm.value);
  }

  users = [
    {
      id_user: 1,
      user_name: 'Mario',
      user_surname: 'Rossi',
      description: 'Richiesta per visualizzare completamente gli elementi archivio',
      access: 0,
    },
    {
      id_user: 2,
      user_name: 'Luigi',
      user_surname: 'Verdi',
      description: 'Richiesta per accedere ai documenti recenti dell\'archivio',
      access: 0,
    },
    {
      id_user: 3,
      user_name: 'Giulia',
      user_surname: 'Bianchi',
      description: 'Richiesta di ricerca all\'interno dell\'archivio',
      access: 0,
    },
    {
      id_user: 4,
      user_name: 'Marco',
      user_surname: 'Neri',
      description: 'Richiesta per scaricare elementi specifici dall\'archivio',
      access: 1,
    },
    {
      id_user: 5,
      user_name: 'Francesca',
      user_surname: 'Gialli',
      description: 'Richiesta per visualizzare le statistiche dell\'archivio',
      access: 0,
    },
    {
      id_user: 6,
      user_name: 'Luca',
      user_surname: 'Blu',
      description: 'Richiesta di accesso temporaneo agli elementi riservati, per ricerca universitaria al fine di creare una tesi di laurea magistrale in storia umanistica.',
      access: 1,
    },
    {
      id_user: 7,
      user_name: 'Anna',
      user_surname: 'Grigi',
      description: 'Richiesta di aggiornamento degli elementi nell\'archivio',
      access: 0,
    },
    {
      id_user: 8,
      user_name: 'Paolo',
      user_surname: 'Viola',
      description: 'Richiesta di esportazione di dati dall\'archivio',
      access: 0,
    },
    {
      id_user: 9,
      user_name: 'Sara',
      user_surname: 'Rossi',
      description: 'Richiesta di backup completo dell\'archivio',
      access: 1,
    },
    {
      id_user: 10,
      user_name: 'Giorgio',
      user_surname: 'Marroni',
      description: 'Richiesta di ripristino degli elementi dell\'archivio',
      access: 0,
    }
  ];
  
    
}
